angular.module('deitz')
            .controller('holdnetsuiteController', [
                    '$scope',
                    '$rootScope',
                    '$http',
                    'apiUrl',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    'commonFactory',
                    '$compile',
                    '$uibModal',
                    'Notification',
                    '$filter',
                    '$timeout',
                    '$q',
                    'SweetAlert',
                    '$state',
                        function (
                            $scope,
                            $rootScope,
                            $http,
                            apiUrl,
                            DTOptionsBuilder,
                            DTColumnBuilder,
                            commonFactory,
                            $compile,
                            $uibModal,
                            Notification,
                            $filter,
                            $timeout,
                            $q,
                            SweetAlert,
                            $state
                        ) {

        $scope.totalRecords = 0;       
        $scope.invoiceArray = {};
        $scope.holdNetsuiteInvoiceArray = [];
        $scope.dateRange;
        $scope.netsuite_status = "Pending";
        $scope.netsuiteStatus = ['All', 'Pending', 'Release In Progress', 'Success'];
        $scope.filterByInvoiceDate = false;

        var excludePaid =  JSON.parse(localStorage.getItem('excludePaid'));

        if(excludePaid) {
            $scope.excludePaid = excludePaid;
        } else {
            $scope.excludePaid = true;
        }

        var excludeClosed =  JSON.parse(localStorage.getItem('excludeClosed'));

        if(excludeClosed) {
            $scope.excludeClosed = excludeClosed;
        } else {
            $scope.excludeClosed = true;
        }

        var excludeVoids =  JSON.parse(localStorage.getItem('excludeVoids'));

        if(excludeVoids) {
            $scope.excludeVoids = excludeVoids;
        } else {
            $scope.excludeVoids = true;
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });

        $scope.selectedBillerIDs = [];
        $scope.selectedJobStatusIDs = [];

        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $scope.report = {
            dateRange: {
                endDate: moment().endOf('month').format('MM-DD-YYYY'),
                startDate: moment().startOf('month').format('MM-DD-YYYY')
            },
        }

        $scope.showNsId = false;
        
        /* Notes browse datatable*/
        $scope.dtInstance = {};

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.$on('reloadInvoiceBrowseBroadcastEvent', function (event, data) {
            $scope.reloadData();
            
        });

        $scope.checkIfHide = function () {
            if($scope.filterByInvoiceDate == false) {
                $scope.reloadData();
            }
        };

        $scope.resetSearch = function(){
            $scope.dtInstance.DataTable.state.clear();
            $scope.report = {
                startDate : null,
                endDate : null
            };
            $scope.excludePaid = true;
            $scope.excludeClosed = true;
            $scope.excludeVoids = true;
            $scope.reloadData();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT

            if (data.INV_AMT < 0) {
                angular.element(row).addClass('voided-invoice');
            }

            if (data.is_void_invoice == '1' || (data.is_voided == '1' && data.INV_NO != data.original_invoice_no)) {
                angular.element(row).attr('title', (data.void_reason ? data.void_reason : 'This invoice voided for Invoice Number : -' + data.original_invoice_no));
            }
            
            if(data.is_voided == '0' && data.is_void_invoice =='0' && (data.INV_NO != data.original_invoice_no)){ 
                
                angular.element(row).attr('title', 'This invoice cloned from Invoice Number : -' + data.original_invoice_no); 
            
            }

            $compile(angular.element(row).contents())($scope);

        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to

                data.where = {};
                data.exVoid = {}; 
                data.orwhere = {};

                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));                
                $scope.inProcess = true;

                localStorage.setItem('excludePaid', $scope.excludePaid);
                localStorage.setItem('excludeClosed', $scope.excludeClosed);
                localStorage.setItem('excludeVoids', $scope.excludeVoids);

                if($scope.filterByInvoiceDate)
                {
                    data.start_date = moment($scope.report.dateRange.startDate).format('YYYY-MM-DD');
                    data.end_date = moment($scope.report.dateRange.endDate).format('YYYY-MM-DD');
                }

                if($scope.excludePaid){
                    data.exPaid = true;
                }

                if($scope.excludeClosed){
                    data.exClosed = true;
                }
                
                if($scope.excludeVoids){
                    data.exVoid = true; 
                }
                
                data.netsuite_status = $scope.netsuite_status;
                
                commonFactory.showDataTable('/api/holdNetsuiteInvoice', data).success(function (res) {
                    if (res.error) {
                        $scope.reloadData();
                    }
                    else {
                        $scope.totalRecords = res.recordsFiltered;                            
                        $scope.selectAll = true;
                        $scope.invoiceArray = {};
                        callback(res);
                    }
                }).error(function (err) {
                    if (err.error !== "token_not_provided") {
                        $scope.reloadData();
                    }
                });
            })
            .withOption('processing', true)
            .withLanguage({
                "sEmptyTable": "NO INVOICE AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ INVOICES",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL INVOICES)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ INVOICES",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "",
                "sZeroRecords": "NO MATCHING INVOICE FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', true)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 600)
            .withDOM('<"html5buttons">lrTgtip')
            .withOption('order', [2, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('initComplete', function(row) 
            {
                for (var i=0; i < row.aoPreSearchCols.length; i++)
                {
                    if (row.aoPreSearchCols[i].sSearch)
                    {
                        var input=$('#invtbl').find('thead > tr').eq(1).find('th').eq(i).find('input');
                        $(input).val(row.aoPreSearchCols[i].sSearch);
                        row.oInit.lightColumnFilterOptions[i].attr = {};
                        row.oInit.lightColumnFilterOptions[i].attr.value = row.aoPreSearchCols[i].sSearch;
                    }
                }
            })
            .withLightColumnFilter({
               '1': {
                    type: 'text',
                    time: 600
               },                   
               '3': {
                    type: 'text',
                    time: 600
                },
               '4': {
                   type: 'text',
                   time: 600
                },
                '5': {
                    type: 'text',
                    time: 600
                },
                '6': {
                   type: 'text',
                   time: 600
                },
                '7': {
                   type: 'text',
                   time: 600
                },
                '8': {
                    type: 'text',
                    time: 600
                 },
                '9': {
                   type: 'text',
                   time: 600
                },
                '10': {
                    type: 'text',
                    time: 600
                }                                       
                                                 
            });

        $scope.dtColumns = [];
        $scope.ori_invoice = {};

        var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAllInvoice(selectAll)">';
            
        $scope.dtColumns.push(
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                
                if(full.ready_for_netsuite_relese == 1)
                {
                    return '';
                }

                const is_checked = $scope.holdNetsuiteInvoiceArray.filter(function (o) {
                    return o.text == full.INV_NO;
                })[0];

                $scope.invoiceArray[full.invoiceID] = full;
                $scope.invoiceArray[full.invoiceID].is_checked = (typeof is_checked === 'undefined') ? false : true;

                if (!$scope.invoiceArray[full.invoiceID].is_checked) {
                    $scope.selectAll = false;
                }

                return '<input type="checkbox" icheck ng-model="invoiceArray[' + full.invoiceID + '].is_checked" ng-change="toggleReleaseToNetsuite(invoiceArray[' + full.invoiceID + '].is_checked,' + full.INV_NO + ', true)">';
            }).withOption('searchable', false),          
            DTColumnBuilder.newColumn('INV_NO').withTitle('Invoice No <hr style="margin-top:5px;margin-bottom:5px"> Orignal Invoice<hr style="margin-top:5px;margin-bottom:5px"> NS ID').withOption('searchable', true).withOption('search', '522123').withOption("width", "10%").renderWith(function(data,type,full,meta){
                
                var netSuiteInvoiceId = '';
                    orignalInvNo = '';
                
                if(full.original_invoice_no && full.original_invoice_no != full.INV_NO) {
                    orignalInvNo = '<br><hr style="margin:5px 0px">'+full.original_invoice_no;
                }    
                
                if(full.netSuiteInvoiceId && $scope.showNsId){
                    netSuiteInvoiceId = '<br><hr style="margin:5px 0px">'+full.netSuiteInvoiceId;
                }
                return  '<a class="pull-left" href="javascript:void(0);" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })" target="_blank">'+full.INV_NO+'</a> '+ orignalInvNo + netSuiteInvoiceId;
            }),
            DTColumnBuilder.newColumn('release_date').withTitle('NS Sent Date').withOption('searchable', true).withOption("width", "6%"),
            DTColumnBuilder.newColumn('JOB_NO').withTitle('Job No <hr style="margin-top:5px;margin-bottom:5px"> Legacy No').withOption('searchable', true).withOption("width", "4%").renderWith(function(data,type,full,meta){

                var data = '<a class="pull-left" href="javascript:void(0);" ui-sref="diary.editcase.step_one({id : ' + full.JOB_NO + ' })" target="_blank">'+full.JOB_NO+'</a>';

                legacy_job_no = '';
                if(full.diary_data.legacy_job_no){
                    legacy_job_no = '<br><hr><span style="font-size:12px;">'+full.diary_data.legacy_job_no+'</span>';
                }
                
                return (data) ? data + legacy_job_no : '';
            }),  
            // DTColumnBuilder.newColumn('diary_data.disposition').withTitle('Court Status').withOption('searchable', true).withOption("width", "4%").withOption('data', 'diary_data.disposition').withOption('name', 'diaryData.disposition'),
            DTColumnBuilder.newColumn('claim_representative.claimRepWithInsuranceComp').withTitle('Claim Rep').withOption("width", "12%").withOption('searchable', true).withOption('data', 'claim_representative.claimRepWithInsuranceComp').withOption('name', 'ClaimRepresentative.CR_CompanyName').renderWith(function (data, type, full, meta) {
                if(data){
                    return full.claim_representative.CR_CompanyName+" | "+full.claim_representative.claimRepName;
                }
                return '';
            }),
            DTColumnBuilder.newColumn('attorney_firms.SHORT_NAME').withTitle('Sold To').withOption("width", "8%").withOption('searchable', true).withOption('data', 'attorney_firms.SHORT_NAME').withOption('name', 'attorneyFirms.SHORT_NAME').renderWith(function (data, type, full, meta) {
                if (data) {
                    data = escapeString(data);
                    var billing_firm = '<a class="pull-left" href="javascript:void(0);" ui-sref="firms.manage.step_one({id : ' + full.attorney_firms.id + ' })" target="_blank">';
                     billing_firm += "<div uib-tooltip='"+full.attorney_firms.NAME+ " | " +full.attorney_firms.PHONE+ " | " +full.attorney_firms.ADDR_LINE1+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                    billing_firm += '</a>';
                    return billing_firm ;
                } else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('diary_data.attorney_firm.SHORT_NAME').withTitle('Scheduling Firm').withOption("width", "8%").withOption('searchable', true).withOption('data', 'diary_data.attorney_firm.SHORT_NAME').withOption('name', 'diaryData.attorneyFirm.SHORT_NAME').renderWith(function (data, type, full, meta) {
                if (data) {
                    data = escapeString(data); 
                    var billing_firm = "<div uib-tooltip='"+full.diary_data.attorney_firm.NAME+ " | "+full.diary_data.attorney_firm.PHONE+ " | " +full.diary_data.attorney_firm.ADDR_LINE1+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                    return billing_firm;
                } else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('attorney_name.NAME').withTitle('Attorney Name').withOption("width", "8%").withOption('searchable', true).withOption('data', 'attorney_name.NAME').withOption('name', 'attorneyName.NAME').renderWith(function (data, type, full, meta) {
                if (data !== null && data !== undefined) {
                    data = escapeString(data); 
                    var attorney_firm = "<div uib-tooltip='"+full.attorney_name.NAME+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                    return attorney_firm;
                } 
                return '-';
            }),
            DTColumnBuilder.newColumn('job.CAPTION').withTitle('Case Name').withOption("width", "8%").withOption('searchable', true).withOption('data', 'job.CAPTION').withOption('name', 'job.CAPTION').renderWith(function (data, type, full, meta) {
                if (data) {
                    var caption = escapeString(data);
                    var indNumFlag = false;
                    
                    if(full.IndexNumber)
                    {
                        indNumFlag = true;
                    }

                    var billing_firm = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+caption+"' | limitTocustom:13}} <br> <a ng-if='"+indNumFlag+"' href='{{depoHost}}/autologin.php?userid={{auth_user.email}}&password={{auth_user.depo_psd}}&script=display_on_elaw.php?indexNumber="+full.IndexNumber+"' target='_blank' title='Display On Elaw'>Elaw</a></div>";
                    return billing_firm;
                } else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('witness').withTitle('Witnesses').withOption('searchable', true).withOption('data', 'witness').notSortable().withOption('name', 'witnessData.witness.NAME').renderWith(function (data, type, full, meta) {
                if (data) {
                    return $filter('uppercase')(data, true);
                } else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('INV_AMT').withTitle('Invoice Amt').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }

            }),
            DTColumnBuilder.newColumn('PAID_AMT').withTitle('Paid Amt').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }

            }),
            DTColumnBuilder.newColumn('PAID_DT').withTitle('Paid Date').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                if (data && $filter('formatDate')(data) != 'Invalid Date') {
                    return $filter('formatDate')(data);
                }
                else {
                    return '-'
                }
            }),
            DTColumnBuilder.newColumn('INV_BAL_DUE').withTitle('Balance Due').withOption('searchable', false).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }
            }),
            DTColumnBuilder.newColumn('dte_invoice').withOption("width", "5%").withTitle('Invoice Date').withOption('searchable', false).withOption('data', 'dte_invoice').withOption('name', 'INVOICE_tbl.dte_invoice').renderWith(function (data, type, full, meta) {
                if(data) {
                    var dte = moment(full.dte_invoice).format('MM/DD/YYYY');
                    if(dte == 'Invalid date'){
                        return '-';
                    }else{
                        return dte;
                    }
                } 
                else {
                    return '-';
                }

            }),
            
            DTColumnBuilder.newColumn('diary_data.businessunit.name').withTitle('Business Unit').withOption('searchable', true).withOption('defaultContent', '-').withOption("width", "6%"),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).withOption("width", "12%").notSortable().renderWith(function (data, type, full, meta) {

                var check = moment(full.dte_invoice, 'YYYY-MM-DD');

                var month = check.format('M');
                var year  = check.format('YYYY');

                const is_printed = (full.is_printed == '1') ? 'Re-Print' : 'Print';

                const print_class = (full.is_printed == '1') ? 'text-navy' : 'text-danger';
                const voided_class = (full.is_voided == '1') ? 'text-danger' : 'text-navy';
                var is_voided = (full.is_voided == '1') ? true : false;
                var is_cod = (full.is_cod == '1') ? true : false;

                const is_void_invoice = (full.is_void_invoice == '1') ? true : false;

                var data = "<div class='text-center'>";
                var bindButtons = '<div class="btn-group btn-sm" uib-dropdown style="color:black!important">' +
                        '<button type="button" class="btn btn-primary btn-sm btn-outline" uib-dropdown-toggle> Action <span class="caret"></span></button>'+
                    '<ul role="menu" uib-dropdown-menu="" style="left: -80px!important;">';


                if (full.is_void_invoice == '1' && full.is_cod == '1') {
                    bindButtons +=
                        '<li uib-tooltip="Recognize invoice" ng-if="havePermission(\'invoice\',\'update\');">' +
                            '<a class="btn btn-xs btn-default" ng-click="markAsRecoginzeInvoice(' + full.voided_for_invoice + ')" ><i class="fa fa-undo"></i> &nbsp; Recognize </a>'+
                        '</li>';

                }else if (full.is_void_invoice == '1') {
                    bindButtons +=
                        '<li uib-tooltip="Undo a voided invoice" ng-if="havePermission(\'invoice\',\'update\');">' +
                            '<a class="btn btn-xs btn-default" ng-click="markAsValidInvoice(' + full.voided_for_invoice + ')"><i class="fa fa-undo"></i> &nbsp; Undo </a>'+
                        '</li>';

                }
                else {
                    

                    bindButtons +=
                        '<li uib-tooltip="Edit An Invoice" ng-if="havePermission(\'invoice\',\'edit\');">' +
                            '<a class="btn btn-xs btn-default" ng-if="!'+is_voided+'" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })" target="_blank"><i class="fa fa-pencil text-navy"></i> &nbsp; Edit </a>'+
                        '</li>';
                    
                    if (!full.PAID_DT) {
                        bindButtons +=    
                        '<li uib-tooltip="Clone An Invoice" ng-if="havePermission(\'invoice\',\'update\');">' +
                            '<a class="btn btn-xs btn-default" ng-if="!' + is_voided + '" ng-click="cloneInvoice('+ full.invoiceID+','+ full.INV_NO+')"><i class="fa fa-copy text-navy"></i> &nbsp; Clone </a></li>';
                    }

                    bindButtons +=
                        '<li uib-tooltip="Voided Invoice">' +
                            '<a class="btn btn-xs btn-default" ng-if="' + is_voided + ' && !'+ is_cod +'" style="cursor: not-allowed"><i class="fa fa-sign-out ' + voided_class + '"></i> &nbsp; Voided Invoice </a>'+
                        '</li>'+

                        '<li uib-tooltip="Already COD Invoice">' +
                            '<a class="btn btn-xs btn-default" ng-if="' + is_voided + ' && '+ is_cod +'" style="cursor: not-allowed"><i class="fa fa-sign-out ' + voided_class + '"></i> &nbsp; Already COD Invoice </a>'+
                        '</li>';

                    if (!full.PAID_DT) {
                        bindButtons +=
                            '<li uib-tooltip="Void the Invoice" ng-if="havePermission(\'invoice\',\'update\');">' +
                                '<a class="btn btn-xs btn-default" ng-if="!' + is_voided + '" ng-click="markAsVoidInvoice(' + full.invoiceID + ',\'\',\'\',' + full.INV_NO + ')"><i class="fa fa-sign-out ' + voided_class + '"></i> &nbsp; Void </a>'+
                            '</li>';   
                    }

                    bindButtons +=
                        '<li uib-tooltip="COD the Invoice" ng-if="havePermission(\'invoice\',\'update\') && !' + is_voided + '">' +
                            '<a class="btn btn-xs btn-default" ng-click="markAsCODInvoice(' + full.invoiceID + ',\'\',\'\',' + full.INV_NO + ')"><i class="fa fa-sitemap ' + voided_class + '"></i> &nbsp; COD </a>'+
                        '</li>'+

                        '<li uib-tooltip="View invoice">' +
                            '<a class="btn btn-xs btn-default" ng-click="viewInvoice(' + full.invoiceID + ')"><i class="fa fa-eye text-navy"></i> &nbsp; View </a>'+
                        '</li>'+

                        '<li uib-tooltip="Cash App">' +
                            '<a class="btn btn-xs btn-default" ng-if="!' + is_voided + '" ui-sref="cash_receipt.singleInvoicePayment({inv_no :'+ full.INV_NO +'})" target="_blank"><i class="fa fa-book text-navy"></i> &nbsp; Cash App </a>'+
                        '</li>';
                }

                if(full.INV_BAL_DUE > 0){
                    bindButtons += '<li uib-tooltip="Payment">' +
                    '<a class="btn btn-xs btn-default" ng-if="!' + is_voided + '" ng-click="payInvoice('+ full.INV_NO + ')"><i class="fa fa-money text-navy"></i> &nbsp; Payment </a>'+
                    '</li>';
                }

                bindButtons +=
                            '<li uib-tooltip="Send Fax" ng-if="havePermission(\'invoice\',\'update\')">' +
                                '<a class="btn btn-xs btn-default" ng-click="sendInvoice(' + full.INV_NO + ',\'fax\')"><i class="fa fa-fax text-navy"></i> &nbsp; Fax </a>'+
                            '</li>'+
                            '<li uib-tooltip="Send Email" ng-if="havePermission(\'invoice\',\'update\')">' +
                                '<a class="btn btn-xs btn-default" ng-click="sendInvoice(' + full.INV_NO + ',\'email\')"><i class="fa fa-envelope text-navy"></i> &nbsp; Email </a>'+
                            '</li>'+
                            '<li uib-tooltip="' + is_printed + '">' +
                                '<a class="btn btn-xs btn-default" ng-click="showInvoicePrint(' + full.INV_NO + ')"><i class="fa fa-print '+ print_class +'"></i> &nbsp; Print </a>'+
                            '</li>'+
                            '<li uib-tooltip="Request Claim Number">' +
                                '<a class="btn btn-xs btn-default" ng-click="getClaimNumberEmailPop(' + full.invoiceID + ')"><i class="fa fa-envelope text-navy"></i> &nbsp; Request Claim Number </a>'+
                            '</li>'
                        '</ul>' +
                    '</div>';

                return bindButtons;

            })
            
        );

        $scope.getClaimNumberEmailPop = function (invoice_id) {
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/email_popup_get_claim_number.html",
                controller: 'viewInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoice_id;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });
        }

        $scope.update_claim_number_popup = function () {
            Notification.success('Email sent successfully!');// DataTables' callback
            $scope.reloadData();
        };

        $scope.payInvoice = function(inv_no){
            if(inv_no){
                $http.get(apiUrl + '/api/pay-invoice-url', {
                    params: {
                        inv_no: inv_no
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.markAsCODInvoice = function (invoice_id, callback, clonedInvoiceId = '', INV_NO) {
            $rootScope.showLoader(true);
            commonFactory.post('/api/invoice/is-collector-exists', {'INV_NO' : INV_NO}).success(function (response) {
                if(!response.result.exists){
                    swal({
                        title: "Error!",
                        type: "error",
                        text: 'Collector does not exists, Please add a collector!',
                        html: true,
                    }); 
                }else{
                    $scope.markCODInvoice(invoice_id, callback, clonedInvoiceId = '', INV_NO);
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        $scope.markCODInvoice = function (invoice_id, callback, clonedInvoiceId = '', INV_NO) {
            $rootScope.showLoader(true);
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/cod_invoice_popup.html",
                controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','commonFactory','Notification', 'clonedInvoiceId', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,commonFactory,Notification) {
                    $rootScope.showLoader(false);
                    $scope.users = [{'id': null, name: "Please select the user"}]
                    $scope.users = $scope.users.concat(users.data);
                    $scope.cod_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'cod', invoice_id: invoice_id, clonedInvoiceId:clonedInvoiceId};
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.codFormSubmit = function ($valid) {
                        if ($valid) {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/invoice/mark-invoice-as-cod', $scope.cod_invoice).success(function (response) {
                                $rootScope.showLoader(false);
                                Notification.success(response.message);
                                $uibModalInstance.close();
                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');

                                //Remove invoice from print batch
                                $rootScope.batchInvoiceArray = $rootScope.batchInvoiceArray.filter(function (item) {
                                    return item.text != INV_NO;
                                });

                                $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray);
                                
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    }

                }],
                resolve: {
                    invoice_id : function () {
                        return invoice_id;
                    },
                    users : ['UsersFactory', function (UsersFactory) {
                        return UsersFactory.get('/api/users').success(function (response) {
                            return response.data;
                        });
                    }],
                    clonedInvoiceId : function(){
                        return clonedInvoiceId;
                    }
                },
                keyboard: true,
                backdrop: false,
                size: 'md',
                windowClass: 'middle-width-model',
            });

            invoiceModal.result.then(function () {
                if(typeof callback =='function')
                callback();
            }, function () {
                if(typeof callback =='function')
                callback();
            });
        };

        $scope.markAsRecoginzeInvoice = function (invoice_id, clonedInvoiceId = '') {
            $rootScope.showLoader(true);

            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/cod_invoice_popup.html",
                controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','invoiceFactory','Notification', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,invoiceFactory,Notification) {
                    $rootScope.showLoader(false);
                    $scope.users = [{'id': null, name: "Please select the user"}]
                    $scope.users = $scope.users.concat(users.data);
                    $scope.cod_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'recognize', invoice_id: invoice_id, clonedInvoiceId:clonedInvoiceId};
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.codFormSubmit = function ($valid) {
                        if ($valid) {
                            $rootScope.showLoader(true);
                            invoiceFactory.post('/api/invoice/mark-invoice-as-cod-undo', $scope.cod_invoice).success(function (response) {
                                $rootScope.showLoader(false);
                                Notification.success(response.message);
                                $uibModalInstance.close();
                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    }

                }],
                resolve: {
                    invoice_id: function () {
                        return invoice_id;
                    },
                    users: ['UsersFactory', function (UsersFactory) {
                        return UsersFactory.get('/api/users').success(function (response) {
                            return response.data;
                        });
                    }],
                    clonedInvoiceId : function(){
                        return clonedInvoiceId;
                    }
                },
                keyboard: true,
                backdrop: true,
                size: 'md',
                windowClass: 'middle-width-model',
                close: function () {

                }
            });

            invoiceModal.result.then(function () {
                $scope.reloadData();
            });             
        }

        $scope.markAsVoidInvoice = function (invoice_id, callback, clonedInvoiceId = '', INV_NO) {

            $rootScope.showLoader(true);
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/void_invoice_popup.html",
                controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','commonFactory','Notification','clonedInvoiceId', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,commonFactory,Notification,clonedInvoiceId) {
                    $rootScope.showLoader(false);
                    $scope.users = [{'id': null, name: "Please select the user"}]
                    $scope.users = $scope.users.concat(users.data);
                    $scope.void_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'void', invoice_id: invoice_id, clonedInvoiceId:clonedInvoiceId};
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.voidFormSubmit = function ($valid) {
                        if ($valid) {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/invoice/mark-invoice-as-void', $scope.void_invoice).success(function (response) {
                                $rootScope.showLoader(false);
                                Notification.success(response.message);
                                $uibModalInstance.close();
                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');

                                //Remove invoice from print batch
                                $rootScope.batchInvoiceArray = $rootScope.batchInvoiceArray.filter(function (item) {
                                    return item.text != INV_NO;
                                });

                                $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray);
                                
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    }

                }],
                resolve: {
                    invoice_id : function () {
                        return invoice_id;
                    },
                    users : ['UsersFactory', function (UsersFactory) {
                        return UsersFactory.get('/api/users').success(function (response) {
                            return response.data;
                        });
                    }],
                    clonedInvoiceId : function(){
                        return clonedInvoiceId;
                    }
                },
                keyboard: true,
                backdrop: false,
                size: 'md',
                windowClass: 'middle-width-model',
            });

            invoiceModal.result.then(function () {
                if(typeof callback =='function')
                callback();
            }, function () {
                if(typeof callback =='function')
                callback();
            });
        };

        $scope.markAsValidInvoice = function (invoice_id) {
            $rootScope.showLoader(true);

            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/void_invoice_popup.html",
                controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','commonFactory','Notification', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,commonFactory,Notification) {
                    $rootScope.showLoader(false);
                    $scope.users = [{'id': null, name: "Please select the user"}];
                    $scope.users = $scope.users.concat(users.data);
                    $scope.void_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'valid', invoice_id: invoice_id};
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.voidFormSubmit = function ($valid) {
                        if ($valid) {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/invoice/mark-invoice-as-valid', $scope.void_invoice).success(function (response) {
                                $rootScope.showLoader(false);
                                Notification.success(response.message);
                                $uibModalInstance.close();
                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    }

                }],
                resolve: {
                    invoice_id: function () {
                        return invoice_id;
                    },
                    users: ['UsersFactory', function (UsersFactory) {
                        return UsersFactory.get('/api/users').success(function (response) {
                            return response.data;
                        });
                    }]
                },
                keyboard: true,
                backdrop: false,
                size: 'md',
                windowClass: 'middle-width-model',
                close: function () {

                }
            });
        }

        $scope.showInvoicePrint = function (invoice_id) {

            if (angular.isArray(invoice_id)) {
                var invoices = invoice_id;
            }
            else {
                var invoices = [{text: invoice_id}];
            }
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/view_popup.html",
                controller: 'viewPrintInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoices;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });


            invoiceModal.result.then(function (is_invoice_printed) {
                if (is_invoice_printed) {
                    var data = {invoice_ids: invoices};
                    //need to update the flag for print
                    commonFactory.post('/api/invoice/mark-as-printed', data).success(function (response) {
                        //console.log(response);
                        $scope.reloadData();
                    }).error(function () {
                        $scope.reloadData();
                    });
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });

        }

        $scope.printWithProof = function () {
            swal({
                    title: "Print with Proof of Order",
                    text: "You Are Going to Print " + $scope.printWithProofInvoices.length + " Invoice with Proof of Order",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Print it.",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    const invoiceIds = $scope.printWithProofInvoices.map((invoice) => invoice.INV_NO);
                    // Generate invoice and open in new tab
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        invoiceFactory.post('/api/invoice/generateBatchInvoice', {invoiceIds}).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error) {
                                $rootScope.showLoader(false);
                                Notification.error(res.result.message);
                            } else {
                                Notification.success(res.result.message.success);
                                $rootScope.showLoader(false);
                                window.open(res.result.pdfUrl, '_blank');
                            }
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err);
                        });
                    }
                });
        }

        $scope.toggleAllInvoice = function (isChecked) {

            angular.forEach($scope.invoiceArray, function (value, key) {
                $scope.invoiceArray[key].is_checked = isChecked;
                $scope.toggleReleaseToNetsuite(isChecked, $scope.invoiceArray[key].INV_NO);
            });
        };

        $scope.toggleReleaseToNetsuite = function (is_checked, invoiceNo, isSingle) {

            const invoiceObject = {text: invoiceNo};
            const index = $scope.holdNetsuiteInvoiceArray.filter(function (o) {
                return o.text == invoiceNo;
            })[0];


            if (is_checked) {
                if (typeof index == 'undefined') {
                    $scope.holdNetsuiteInvoiceArray.push(invoiceObject);
                }
            }
            else {

                if (typeof index !== 'undefined') {

                    $scope.holdNetsuiteInvoiceArray = $scope.holdNetsuiteInvoiceArray.filter(function (item) {
                        return item.text != invoiceNo;
                    });
                }
            }

            //check the main checkbox is selected
            var keepGoing = true;
            $timeout(function () {
                angular.forEach($scope.invoiceArray, function (value, key) {
                    if (keepGoing) {
                        if (!$scope.invoiceArray[key].is_checked) {
                            $scope.selectAll = false;
                            keepGoing = false;
                        }
                        else {
                            $scope.selectAll = true;
                        }
                    }

                });
            });
        };

        $scope.releaseToNetsuite = function () {
            
            if($scope.holdNetsuiteInvoiceArray.length == 0) {
                Notification.error("No invoices selected to release.");
                return;
            }

            let inv_arr = [];

            $scope.holdNetsuiteInvoiceArray.forEach(element => {
                inv_arr.push(element.text);
            });

            $rootScope.showLoader(true);
            //Release all selected invoices to Netsuite.
            commonFactory.post('/api/release-invoices-to-netsuite', {invoiceArray:inv_arr})
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if(response.error){
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else{
                        Notification.success(response.result.message);
                        $scope.holdNetsuiteInvoiceArray = [];
                        $scope.invoiceArray = {};
                        $scope.reloadData();
                    }
                });
        };

        $scope.viewInvoice = function (invoice_id) {
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/invoice_view.html",
                controller: 'viewInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoice_id;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            {
                                files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                            },
                            {
                                insertBefore: '#loadBefore',
                                name: 'localytics.directives',
                                files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                            },
                        ]);
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });


            invoiceModal.result.then(function (is_invoice_printed) {
                if (is_invoice_printed) {
                    // var data = {invoice_ids : invoices};
                    // need to update the flag for print
                    //$scope.updateInvoiceFlagForPrinted(invoices);
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });
            ;
        };

        $scope.cloneInvoice = function (invoice_id, INV_NO) {

            swal({
                    title: "Are you sure ?",
                    text: "Are you sure want to clone that invoice?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        // Delete the invoice
                        commonFactory.post('/api/invoice/clone-invoice',{invoice_id : invoice_id}).success(function (response) {
                            $rootScope.showLoader(false);
                            Notification.success(response.message);

                            var clonedInvoiceId = response.cloned_invoice.invoiceID;

                            swal({
                                    title: "Are you sure ?",
                                    text: "Do you want to void the original invoice?",
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#09375f",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },
                                function (isConfirm) {
                                    if (isConfirm) {

                                        $scope.markAsVoidInvoice(invoice_id,function(){

                                            swal({
                                                    title: "Are you sure ?",
                                                    text: "Do you want to edit the New Invoice?",
                                                    type: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#09375f",
                                                    confirmButtonText: "Yes",
                                                    cancelButtonText: "No",
                                                    closeOnConfirm: true,
                                                    closeOnCancel: true
                                                },
                                                function (isConfirm) {
                                                    if(isConfirm) {
                                                        $state.go('invoice.edit', {invoice_id: clonedInvoiceId});
                                                    }else{
                                                        $scope.reloadData();
                                                    }
                                                });
                                        }, clonedInvoiceId, INV_NO);

                                    }else{
                                        $scope.reloadData();
                                    }

                            });
                        }).error(function (error) {
                            $rootScope.showLoader(false);
                            Notification.error(error.message);
                        });
                    }
                });
        };
    }
]);
